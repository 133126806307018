import React from "react";

import "./PageNotFoundContent.css";

const Highlighted404Component = () => {
    return (
            <div className="center-item">
                <div className="highlight-404">
                    <h1 className="title-404">404</h1>
                </div>
            </div>
    );
}

export default Highlighted404Component;