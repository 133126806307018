import React from 'react';

import BackToHomepageRedirect from '../common/BackToHomepage/BackToHomepageRedirect';
import Highlighted404Component from "./Highligted404Component";
import "./PageNotFoundContent.css";

const PageNotFoundContent = () => {
    return (
        <>
            <Highlighted404Component />
            <p className="subtitle center-item">Oops this is awkward...</p>
            <BackToHomepageRedirect />
        </>
    );
}

export default PageNotFoundContent;