import * as React from "react"
import PageNotFoundContent from "../components/404Page/PageNotFoundContent"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <PageNotFoundContent />
  </Layout>
)

export default NotFoundPage
